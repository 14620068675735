.mainSection {
  display: flex;
  flex-direction: column;
  contain: layout;
}

.displayFlex {
  display: flex;
}

.displayBlock {
  display: block !important;
}

.contentPadding {
  padding-bottom: 1% !important;
  display: flex;
}

.btnMargin {
  margin: 30px;
}
