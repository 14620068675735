.header-card {
  width: 100% !important;
  text-align: center;
}

.questions-container {
  padding-top: 32px;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.slide-in {
  animation: slideInFromRight 0.3s ease-in-out;
}

.alert {
  position: absolute;
  z-index: 1;
  right: 0;
  width: fit-content !important;
}

.survey-responses .shell-accordion-panel-header {
  background-color: #3173c9 !important;
  color: #fff !important;
}

.survey-responses .shell-accordion-panel-header path {
  fill: #fff;
}

.survey-responses table {
  text-align: left !important;
}

.project-result-container {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.edt-question a {
  font-weight: bold;
  text-decoration: underline;
}
