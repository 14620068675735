.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-wrapper {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.modal-cross {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-content h2 {
  margin-top: 10px; /* Adjust this value to reduce the space below the h2 element */
  margin-bottom: 10px;
}

.modal-content p {
  margin: 10px 0; /* Adjust this value to reduce the space below the p element */
}

.timer {
  color: red; /* Change this to the color you want */
  font-weight: bold; /* Makes the timer bold */
}

.continue-button {
  width: 120px;
}

.disable-pointer-events {
  pointer-events: none;
}