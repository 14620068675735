html {
  height: 100%;
  background: rgb(245, 245, 245);
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

nav,
header {
  position: fixed;
}

main {
  width: 80%;
  height: 100%;
  margin: auto;
  padding-left: 76px;
  padding-top: 64px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #003c88;
}

.MuiCollapse-wrapperInner {
  background-color: #f8f8f8 !important;
}
.MuiSlider-markLabel {
  font-size: 0.8rem !important;
}

.auth-loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
