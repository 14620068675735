.card-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-card {
  width: 100% !important;
}

.contact-card img {
  width: 85%;
  height: 85%;
}

.contact-avatar-img {
  justify-content: center;
  margin-bottom: 20px;
}
