.exist-container {
  width: 70%;
  margin: auto;
  padding-top: 40px;
  height: 100vh;
}

.project-details {
  margin-top: 20px;
  padding: 20px;
}

.contact-values {
  display: flex;
  gap: 12px;
  align-items: center;
}