.contentPadding {
  padding: 0px !important;
}

.subContent {
  text-align: left;
  padding: 15px 5px;
  margin-top: 8;
}

.cursorPointer {
  cursor: pointer;
}

.commentSection {
  width: 0.75em !important;
  height: 0.65em !important;
}

.mainSection {
  display: flex;
  flex-direction: column;
}

.cardSection {
  padding: 5px 5px;
}

.title {
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 14px !important;
}

.heading {
  background-color: #011638 !important;
  color: #fff !important;
  padding: 0px 6px;
}

.cardHeader {
  padding: 10px !important;
  text-transform: uppercase;
}
