.card-paper {
  width: 100% !important;
  margin-bottom: 40px;
}

/* .vertical {
  height: 300px;
} */

/* .ram-view-container {
  margin: 20px 0;
} */

.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ram-result-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
