.SummaryHeader {
  grid-area: SHeader;
  text-align: left;
}

.SummaryDetails {
  grid-area: SDetails;
  text-align: left;
}

.OverallScore {
  grid-area: OScore;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.CHeader {
  grid-area: CHeader;
  text-align: center;
  text-transform: uppercase;
}

.CScoreL {
  grid-area: CScoreL;
  text-align: center;
}

.SEHeader {
  grid-area: SEHeader;
  text-align: center;
  text-transform: uppercase;
}

.SEScoreL {
  grid-area: SEScoreL;
  text-align: center;
}
.gridContainer {
  display: grid;
  grid-template-areas:
    "OScore CHeader SEHeader"
    "OScore CScoreL SEScoreL";
  padding: 10px;
}

.SummaryDetailWrapper {
  padding-left: 38px;
}
