.faq-container .shell-accordion-panel-header-text {
  /* text-transform: uppercase; */
  padding-left: 20px;
}

.faq-questions-container {
  padding: 20px;
}

.faq-container .shell-accordion-panel-item {
  margin-bottom: 0px;
}
