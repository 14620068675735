.signed-out-container {
  text-align: center;
  padding: 0px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 30px;
  margin: 20px; /* adjust as needed */
}

.signed-out-image {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.logo-image {
  width: 150px; /* adjust as needed */
  height: auto;
  padding-bottom: 10px;
}