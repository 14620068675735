.stepper-container {
  width: 100% !important;
}

.survey-stepper:nth-child(3) {
  flex-basis: auto !important;
}

.category-text {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 0.875rem !important;
}

.survey-btn-container {
  display: flex;
  justify-content: center !important ;
}

/* .question-text {
  display: flex;
} */

.shell-tooltip {
  font-size: 0.875 !important;
  /* color: white !important;
  background-color: rgb(64, 64, 64); */
}

/* .dhc-questions .shell-modal-container-portal {
  background-color: rgb(64, 64, 64) !important;
} */
