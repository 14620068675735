.page-title {
  text-transform: uppercase;
  padding-left: 8px;
  display: flex;
  align-items: left;
}

.logo-image-1 {
  width: 150px; /* adjust as needed */
  height: 70px; /* adjust as needed */
  margin-right: 10px; /* adjust as needed */
  padding-bottom: 10px;
  padding-top: 5px;
}

.user-info {
  margin-right: 16px;
}

header div:nth-child(2) {
  justify-content: flex-start !important;
}
