.dashboard-header {
  margin-top: 20px;
}

.dashboard-container {
  display: flex;
  justify-content: space-between;
}

.export-all-edt {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: 20px 0; /* Adjust the padding as needed */
}

.dashboard-filters {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  align-items: center;
  margin: 20px 0px;
}

.shell-accordion-panel-item-active .shell-accordion-panel-header {
  background-color: #ffc600;
}

.survey-results .shell-accordion-panel-header {
  background-color: rgba(49, 115, 201, 1);
  color: rgba(255, 255, 255, 1);
}

.sub-content .shell-accordion-panel-header {
  background-color: rgba(49, 115, 201, 1);
  color: rgba(255, 255, 255, 1);
}

.sub-content .shell-accordion-panel-header path {
  fill: rgba(255, 255, 255, 1);
}

.survey-results .shell-accordion-panel-header path {
  fill: rgba(255, 255, 255, 1);
}

.sub-content .shell-accordion-panel-header-text {
  text-transform: uppercase;
  padding-left: 20px;
}

.survey-results .shell-accordion-panel-expand-icon {
  color: rgba(255, 255, 255, 1);
}

.shell-accordion-panel-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.shell-accordion-panel-header-text {
  flex: 90%;
}

.panel-header {
  display: flex;
  justify-content: space-around;
  padding-left: 8%;
  padding-right: 11%;
}

.panel-header div {
  flex-basis: 33.33%;
}

.shell-accordion-panel-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 28px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.survey-results {
  width: 100% !important;
  margin: 20px 0px;
}

table {
  table-layout: fixed !important;
  width: 100%;
}

.export-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-survey-panel {
  width: 100% !important;
  margin: 20px 0px;
}

.summary-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

h4 {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}

.score-indicator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shell-accordion-panel-content {
  padding: 0 !important;
}

.date-toggle {
  cursor: pointer;
  display: flex;
}
