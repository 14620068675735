.form-container {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 30px;

}

.footer p {
  color: rgb(117, 117, 117) !important;
  font-style: italic !important;
  text-align: center;
}

.contact-label {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* -webkit-letter-spacing: 0em !important;
  -moz-letter-spacing: 0em !important; */
  -ms-letter-spacing: 0em !important;
  letter-spacing: 0em !important;
  font-style: normal !important;
  color: rgba(52, 52, 52, 1) !important;
  margin-bottom: 8px !important;
}

.shell-tag {
  padding: 8px !important;
}

.contact-values {
  display: flex;
  gap: 12px;
}

.add-contact {
  background-color: rgba(75, 120, 170, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}

.bDjYHN svg path {
  fill: white !important;
}

.hGONTs.shell-select-disabled
  .shell-select-selector
  .shell-select-selection-search-input,
.hGONTs.shell-select-disabled
  .shell-select-selector
  .shell-select-selection-item,
.hGONTs.shell-select-disabled
  .shell-select-selector
  .shell-select-selection-placeholder {
  color: unset !important;
}

.hGONTs.shell-select-disabled
  .shell-select-selector
  .shell-select-selection-item {
  background: unset !important;
}

.hGONTs.shell-select-disabled .shell-select-selector {
  border-color: unset !important;
  background-color: unset !important;
}

.lowerHalf-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
}